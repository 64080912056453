import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'overlay']

  connect() {
    this.isOpen = false
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.handleView()
  }

  close() {
    this.isOpen = false
    this.handleView()
  }

  handleView() {
    if (this.isOpen) {
      this.data.set('open', true)
      this.menuTarget.classList.remove(this.menuClass)
      this.overlayTarget.classList.remove(this.overlayClass)
    } else {
      this.data.set('open', false)
      this.menuTarget.classList.add(this.menuClass)
      this.overlayTarget.classList.add(this.overlayClass)
    }
  }

  get menuClass() {
    return this.data.get('menu-class')
  }

  get overlayClass() {
    return this.data.get('overlay-class')
  }
}
