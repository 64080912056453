import { Controller } from 'stimulus'

import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'

export default class extends Controller {
  connect() {
    new Splide(this.element, {
      type: 'loop',
      arrows: false,
      pagination: false,
      autoplay: true,
      gap: '1rem',
      interval: 3000,
      perPage: 5,
      breakpoints: {
        640: {
          perPage: 3,
        },
      },
    }).mount()
  }
}
