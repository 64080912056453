import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['header']

  initialize() {
    this.headerHeight = this.headerTarget.offsetHeight
    this.initialScrollPosition = window.scrollY + this.headerHeight

    this.userIsScrolling()
  }

  connect() {
    this.element.style.paddingTop = `${this.headerHeight}px`
  }

  userIsScrolling() {
    if (window.scrollY > this.initialScrollPosition) {
      this.headerTarget.classList.add('app-header-up')
    }

    if (window.scrollY < this.initialScrollPosition) {
      this.headerTarget.classList.remove('app-header-up')
    }
  }
}
