import { Controller } from 'stimulus'

import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'

export default class extends Controller {
  static targets = ['slide', 'pagination']

  initialize() {
    this.slideHeight

    this.sliderController = new Splide(this.element, {
      type: 'loop',
      height: `${this.maxSlideHeight + 60}px`,
      direction: 'ttb',
      arrows: false,
      nav: true,
      autoplay: true,
      interval: 6000,
    })
  }

  connect() {
    this.sliderController.mount()
  }

  get slideHeight() {
    this.maxSlideHeight = 0

    for (const slide of this.slideTargets) {
      if (slide.offsetHeight >= this.maxSlideHeight) {
        this.maxSlideHeight = slide.offsetHeight
      }
    }
  }
}
