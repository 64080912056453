import { Controller } from 'stimulus'

import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'

export default class extends Controller {
  static targets = ['slide', 'pagination']

  connect() {
    new Splide(this.element, {
      type: 'loop',
      arrows: false,
      nav: true,
      autoplay: true,
      interval: 6000,
    }).mount()
  }
}
